<template>
    
    <b-overlay
      :show="is_loading"
      spinner-variant="primary"
      variant='transparent'
      style="min-height: 50vh;"
    >
        
        <b-card no-body>
            <b-card-header>
                <b-card-title>
                    Тикеты
                </b-card-title>
            </b-card-header>
            <b-skeleton-table v-if='is_loading' animation="fade" :columns='8' :rows='5'>
            
            </b-skeleton-table>
            <b-table-simple v-else class="font-small-3 text-black tickers-table" responsive>
<!--                <b-thead>
                    <b-tr>
                        <b-td>#</b-td>
                        <b-td>Пользователь</b-td>
                        <b-td>Категория</b-td>
                        <b-td>Контракт</b-td>
                        <b-td>Майнер</b-td>
                        <b-td>Сообщение</b-td>
                        <b-td></b-td>
                        <b-td>Статус</b-td>
                        <b-td>#</b-td>
                        <b-td>#</b-td>
                    </b-tr>
                </b-thead>-->
                <b-tbody>
                    <b-tr v-for="item in items" :key="item.id" class="cursor-pointer" v-ripple  @click="$router.push({ name: 'admin-tickets-view', params: { ticket: item, id: item.id } })">
                        <!--<b-td>{{ item.id }}</b-td>-->
<!--                        <b-td class="text-black font-weight-bolder">
                            <template v-if="item.contract">
                                {{ item.contract.guuid }}
                            </template>
                            <template v-else-if="item.miner">
                                {{ item.miner.guuid }}
                            </template>
                        </b-td>-->
                        <b-td style="width: 35px;" class=" pl-1 pr-1">
                            <b-form-checkbox />
                        </b-td>
                        <b-td class="pl-0 pr-0" style="white-space: nowrap;">
                            <div class="d-flex align-items-center">
                                <div>
                                    <b-avatar
                                        :variant="Users.utils.resolveAvatarColor( item.user.id )"
                                        :text="Users.utils.capitalize(`${item.user.firstName}`, 1 )"
                                    />
                                </div>
                                <div class="d-flex flex-column" style="margin-left: 16px;">
                                    <span class="text-capitalize m-0 text-black font-weight-bolder" >
                                        {{ item.user.firstName }}  {{ item.user.lastName }}
                                    </span>
                                     
                                    <span class="text-black text-muted text-nowrap ">
                                        {{ item.subject.title }}   
                                    </span>
                                </div>
                            </div>
                        </b-td>
                       
                        <b-td>
                            <div class="d-flex">                                
                                <span
                                    v-html="item.message.text.replace(/<(.|\n)*?>/g, '')" 
                                    class="text-ellipsis text-black"
                                    style="display: -webkit-box; -webkit-line-clamp: 2;  -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;  font-size: 13px; opacity: 0.75; "
                                ></span>
                            </div>
                        </b-td>
                        
                        <b-td>
                            <div class="d-flex flex-column">
                                <span class="text-black font-small-3 text-nowrap font-weight-bolder">
                                    {{ utils.lastActivityFormat(item.updated_at) }}
                                </span>
                                <span v-if="item.message.read === false && item.message.user == item.user.id " class="text-nowrap">
                                    Новое сообщение
                                </span>
                                <span v-else-if="item.is_closed" >Закрытый</span>
                                <span v-else >Открытый</span>
                            </div>
                        </b-td>
                        
                        <!--
                        <b-td>
                            <b-badge v-if="item.message.read === false && item.message.user !== $user.self.id" variant="primary" style="margin-right: 5px;">
                                NEW
                            </b-badge>
                            <b-badge v-else-if="item.is_closed" variant="danger">CLOSED</b-badge>
                            <b-badge v-else variant="success">OPEN</b-badge>
                        </b-td>
                        <b-td>#</b-td>
                        <b-td>#</b-td>-->
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-card>
        
<!--         <b-card no-body class="overflow-hidden border" bg-variant="transparent" >
            <b-row>
                <b-col md="4" lg="4" cols="12" class='border-right pr-0' >
                    <div class='overflow-scroll p-relative' style='height: calc(100vh - 66px)'>
                        <b-card no-body style='border-radius: 0 !important; min-height: 74px; display: sticky; top: 0px; z-index: 999;' class='mb-0 p-relative'  >
                            <b-card-header class='p-1'>
                               
                            </b-card-header>
                        </b-card>
                        <b-card v-for="item in items" no-body :key="item.id" @click="$router.push({ name: 'admin-tickets-view', params: { ticket: item, id: item.id } })" v-ripple class="hovering-zoom cursor-pointer p-0 m-0 border-right-0 border-left-0 border-bottom-0 border-top " style="border-radius: 0 !important; "> 
                            <b-card-body :class="{
                                        'd-flex' : true,
                                        'bg-chat-unread' : item.user.id === item.message.user && item.message.read === false
                                    }" style="padding: 10px 16px; margin: 0; border: none;">
                                <div class="d-flex">
                                    <b-avatar
                                        :variant="Users.utils.resolveAvatarColor( item.user.id )"
                                        :text="Users.utils.capitalize(`${item.user.firstName}`, 1 )"
                                        style="width: 40px; height: 40px; font-size: 22px;"
                                    />
                                </div>
                                <div class="flex-grow-1" style="margin-left: 12px;">
                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex flex-column" style="font-size: 15px;">
                                            <span class="text-capitalize m-0 text-black">
                                                {{ item.user.firstName }} {{ item.user.lastName }}
                                            </span>
                                        </div>
                                        <div>
                                            <span class="text-muted font-small-3">
                                                {{ utils.lastActivityFormat(item.updated_at) }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between" style="margin-top: 0px;">
                                        <b-card-text 
                                            v-html="item.message.text.replace(/<(.|\n)*?>/g, '')" 
                                            class="text-ellipsis"
                                            style="display: -webkit-box; -webkit-line-clamp: 2;  -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis; opacity: 0.55; "
                                        ></b-card-text>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span class="text-muted font-small-3">
                                            {{ item.subject.title }} 
                                        </span>
                                        <b-badge v-if="item.is_closed" variant="danger">
                                            Закрыт
                                        </b-badge>
                                        <span v-else class="text-primary font-small-3">
                                            Открыт
                                        </span>
                                    </div>
                                </div>

                            </b-card-body>
                        </b-card>
                    </div>
                </b-col>

                <b-col md="8" lg="8" cols="12" class='border-right p-0' style='height: calc(100vh - 66px)'>
                    <router-view />
                </b-col>

            </b-row>
        </b-card>-->
    </b-overlay>
    
</template>

<script>
    
    import Users from "@/modules/admin/users/"
    import utils from "@/modules/utils/"
    
    export default {

        data() {
            return {
                Users,
                utils,
                is_loading: true,
                items: []
            }
        },
        methods: {

        },
        components: {

        },
        watch: {

        },
        mounted() {
            this.$request.get("tickets/list", this.$route.query ).then( rsp => {
                this.is_loading = false;
                this.items = rsp.items;
            });
        }

    }

</script>
